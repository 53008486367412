import React, { useCallback, useMemo } from 'react';
import './mediaHotspotLine.scss';

const MediaHotspot = ({ media, height, goToScene }) => {
  const contents = useMemo(() => {
    const res = [];
    const title = media.title || '';
    if (title && title !== '.') {
      res.push(title);
    }
    const description = (media.infoTag?.text || '').split('\n');
    res.push(...description);
    return res;
  }, [media]);

  const imgUrl = useMemo(() => {
    if (media.type === 'Image') {
      return media.imageURL;
    }
    return null;
  }, [media]);

  const isSalesGallery = useMemo(() => media.id === 'sales-gallery', [media]);

  const onHover = () => {
  };

  const handleClick = useCallback(() => {
    if (isSalesGallery) {
      goToScene('can-ho-mau', '2');
    }
  }, [goToScene, isSalesGallery]);

  return (
    <div
      className={`khp-media-hotspot ${isSalesGallery ? 'big-bottom' : ''}`}
      onMouseEnter={onHover}
      onClick={handleClick}
    >
      <div
        className="khp-media-hotspot__container"
        style={{ height: `${height * 50}px` }}
      >
        <div className="khp-media-hotspot__title">
          {contents.map((txt, index) => (
            <p key={index} className={contents.length > 2 ? 'text-left' : ''}>
              {txt}
            </p>
          ))}
          {imgUrl && (
            <img
              className="khp-media-hotspot__preview"
              src={imgUrl}
              alt={media.title}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MediaHotspot;
