// Initiate
export const INITIATE = 'INITIATE';
export const INITIATED = 'INITIATED';
// Tour
export const SET_TOUR_DATA = 'SET_TOUR_DATA';
// Menu
export const TOGGLE_MENU = 'TOGGLE_MENU';
export const SET_MENU_POSITION = 'SET_MENU_POSITION';
// Pano (Scene)
export const TOGGLE_PANO_MODE = 'TOGGLE_PANO_MODE';
export const SET_PANO_DATA = 'SET_PANO_DATA';
export const GO_TO_PANO = 'GO_TO_PANO';
// Landing
export const CLOSE_LANDING = 'CLOSE_LANDING';
export const TOGGLE_LANDING = 'TOGGLE_LANDING';
// Map 3D Data
export const SET_AERIAL_MAP_DATA = 'SET_AERIAL_MAP_DATA';
export const ADD_HOTSPOT_HISTORY = 'ADD_HOTSPOT_HISTORY';
// Camera Control
export const SET_CONTROL_MODE = 'SET_CONTROL_MODE';
export const SET_GYRO = 'SET_GYRO';
export const SET_CENTER_LOOKAT = 'SET_CENTER_LOOKAT';

// Audio
export const TOGGLE_AUDIO = 'TOGGLE_AUDIO';
export const SET_AUDIO_AVAILABLE = 'SET_AUDIO_AVAILABLE';
export const SET_AUDIO_AUTOPLAY = 'SET_AUDIO_AUTOPLAY';

// TourAudio
export const SET_TOUR_AUDIO_AVAILABLE = 'SET_TOUR_AUDIO_AVAILABLE';

// Dimention
export const UPDATE_DIMENTIONS = 'UPDATE_DIMENTIONS';

// Blur
export const TOGGLE_BLUR = 'TOGGLE_BLUR';

export const SET_TOUR_ERROR = 'TOUR_ERROR';

// View
export const SET_VIEW_MODE = 'SET_VIEW_MODE';

export const SET_AUTO_PLAY = 'SET_AUTO_PLAY';

export const UPDATE_PREV_ROTATION = 'UPDATE_PREV_ROTATION';

export const SET_AUTO_ROTATE = 'SET_AUTO_ROTATE';

export const SET_SEARCH_PARAMS = 'SET_SEARCH_PARAMS';

export const SET_OPEN_POPUP = 'SET_OPEN_POPUP';

export const UPDATE_WORLD_PARAMS = 'UPDATE_WORLD_PARAMS';

export const SET_IS_PANNING = 'SET_IS_PANNING';

export const SET_DATE_LIGHT = 'SET_DATE_LIGHT';
