import React, { useEffect, useMemo, useRef, useState } from 'react';
import compassImg from './laban-400px.png';
import compassArrowImg from './arrow-laban.png';
import RotationHandler from './RotationHandler';

import './style.scss';

const radiansToDegrees = (radians) => {
  return Math.round(radians * (180 / Math.PI));
};

const Compass = ({ deg }) => {
  const compassRef = useRef(null);
  useEffect(() => {
    // nếu deg null thì ko xoay
    compassRef.current.style.transform = `rotate(${deg || 0}deg)`;
  }, [deg, compassRef]);

  return (
    <div className="compass-container">
      <div className="compass-icon">
        <img src={compassImg} alt="compass" />
      </div>
      <div ref={compassRef} className="compass-arrow">
        <img src={compassArrowImg} alt="compass-arrow" />
      </div>
    </div>
  );
};

const COMPASS_DATA = {
  'tong-the-toan-canh-01': {},
  'tong-the-toan-canh-01---ban-o8em': {},
  'tong-the-toan-canh-02': {},
  'tong-the-toan-canh-02---ban-wcem': {},
  'topview-tang-27': {},
  'topview-tang-22': {},
  'topview-tang-15': {},
  'topview-tang-07': {},

  'mika-topview-tang-27': {},
  'mika-topview-tang-22': {},
  'mika-topview-tang-15': {},
  'mika-topview-tang-07': {},
};

const COMPASS_DEGREE_DATA = {
  'tong-the-toan-canh-01': 45,
  'tong-the-toan-canh-01---ban-o8em': 45,
  'tong-the-toan-canh-02': -45,
  'tong-the-toan-canh-02---ban-wcem': -125,
  'topview-tang-27': -18,
  'topview-tang-22': -18,
  'topview-tang-15': -18,
  'topview-tang-07': -18,
  'mika-topview-tang-27': -18,
  'mika-topview-tang-22': -18,
  'mika-topview-tang-15': -18,
  'mika-topview-tang-07': -18,
};

const CompassContainer = ({
  currentPano,
  cameraParams,
  sceneRotation,
  ...props
}) => {
  const [rotationY, setRotationY] = useState(0);
  const isVisible = useMemo(() => {
    if (currentPano?.id && COMPASS_DATA[currentPano?.id]) {
      return true;
    }
    return false;
  }, [currentPano]);

  const defaultDegree = useMemo(() => {
    if (currentPano?.id && COMPASS_DEGREE_DATA[currentPano?.id]) {
      return COMPASS_DEGREE_DATA[currentPano?.id];
    }
    return 0;
  }, [currentPano]);

  const compassDeg =
    useMemo(() => radiansToDegrees(rotationY), [rotationY]) || 0;

  if (!isVisible) return null;

  return (
    <>
      {currentPano && (
        <RotationHandler
          cameraParams={cameraParams}
          sceneRotation={sceneRotation}
          setRotationY={setRotationY}
          currentPano={currentPano}
        />
      )}
      <Compass {...props} deg={compassDeg + defaultDegree} />
    </>
  );
};

export default CompassContainer;
